exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-meglife-js": () => import("./../../../src/pages/about-meglife.js" /* webpackChunkName: "component---src-pages-about-meglife-js" */),
  "component---src-pages-acessibility-js": () => import("./../../../src/pages/acessibility.js" /* webpackChunkName: "component---src-pages-acessibility-js" */),
  "component---src-pages-advance-gallery-js": () => import("./../../../src/pages/advance_gallery.js" /* webpackChunkName: "component---src-pages-advance-gallery-js" */),
  "component---src-pages-advancegellery-js": () => import("./../../../src/pages/Advancegellery.js" /* webpackChunkName: "component---src-pages-advancegellery-js" */),
  "component---src-pages-all-notice-js": () => import("./../../../src/pages/all_notice.js" /* webpackChunkName: "component---src-pages-all-notice-js" */),
  "component---src-pages-animated-tree-js": () => import("./../../../src/pages/animated_tree.js" /* webpackChunkName: "component---src-pages-animated-tree-js" */),
  "component---src-pages-article-display-js": () => import("./../../../src/pages/article_display.js" /* webpackChunkName: "component---src-pages-article-display-js" */),
  "component---src-pages-article-js": () => import("./../../../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-chart-filter-js": () => import("./../../../src/pages/chart-filter.js" /* webpackChunkName: "component---src-pages-chart-filter-js" */),
  "component---src-pages-comunity-develop-activities-js": () => import("./../../../src/pages/comunity_develop_activities.js" /* webpackChunkName: "component---src-pages-comunity-develop-activities-js" */),
  "component---src-pages-comunity-development-js": () => import("./../../../src/pages/comunity_development.js" /* webpackChunkName: "component---src-pages-comunity-development-js" */),
  "component---src-pages-consultancies-js": () => import("./../../../src/pages/consultancies.js" /* webpackChunkName: "component---src-pages-consultancies-js" */),
  "component---src-pages-contact-from-js": () => import("./../../../src/pages/contact_from.js" /* webpackChunkName: "component---src-pages-contact-from-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact_us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-executive-js": () => import("./../../../src/pages/Executive.js" /* webpackChunkName: "component---src-pages-executive-js" */),
  "component---src-pages-filttergellery-js": () => import("./../../../src/pages/filttergellery.js" /* webpackChunkName: "component---src-pages-filttergellery-js" */),
  "component---src-pages-gallery-22-js": () => import("./../../../src/pages/gallery22.js" /* webpackChunkName: "component---src-pages-gallery-22-js" */),
  "component---src-pages-gallery-3-js": () => import("./../../../src/pages/Gallery3.js" /* webpackChunkName: "component---src-pages-gallery-3-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery-index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gis-js": () => import("./../../../src/pages/Gis.js" /* webpackChunkName: "component---src-pages-gis-js" */),
  "component---src-pages-goods-equipment-js": () => import("./../../../src/pages/goods-equipment.js" /* webpackChunkName: "component---src-pages-goods-equipment-js" */),
  "component---src-pages-governing-council-js": () => import("./../../../src/pages/Governing-council.js" /* webpackChunkName: "component---src-pages-governing-council-js" */),
  "component---src-pages-grievance-redressal-js": () => import("./../../../src/pages/Grievance_redressal.js" /* webpackChunkName: "component---src-pages-grievance-redressal-js" */),
  "component---src-pages-hero-image-js": () => import("./../../../src/pages/hero_image.js" /* webpackChunkName: "component---src-pages-hero-image-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institutional-js": () => import("./../../../src/pages/Institutional.js" /* webpackChunkName: "component---src-pages-institutional-js" */),
  "component---src-pages-latest-notification-js": () => import("./../../../src/pages/latest-notification.js" /* webpackChunkName: "component---src-pages-latest-notification-js" */),
  "component---src-pages-latest-tender-js": () => import("./../../../src/pages/latest-tender.js" /* webpackChunkName: "component---src-pages-latest-tender-js" */),
  "component---src-pages-management-body-js": () => import("./../../../src/pages/management_body.js" /* webpackChunkName: "component---src-pages-management-body-js" */),
  "component---src-pages-meglife-dashboard-js": () => import("./../../../src/pages/meglife-dashboard.js" /* webpackChunkName: "component---src-pages-meglife-dashboard-js" */),
  "component---src-pages-news-letters-js": () => import("./../../../src/pages/news_letters.js" /* webpackChunkName: "component---src-pages-news-letters-js" */),
  "component---src-pages-no-consultancies-js": () => import("./../../../src/pages/no_consultancies.js" /* webpackChunkName: "component---src-pages-no-consultancies-js" */),
  "component---src-pages-notice-display-js": () => import("./../../../src/pages/notice_display.js" /* webpackChunkName: "component---src-pages-notice-display-js" */),
  "component---src-pages-notice-js": () => import("./../../../src/pages/notice.js" /* webpackChunkName: "component---src-pages-notice-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-photo-js": () => import("./../../../src/pages/photo.js" /* webpackChunkName: "component---src-pages-photo-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-popup-js": () => import("./../../../src/pages/popup.js" /* webpackChunkName: "component---src-pages-popup-js" */),
  "component---src-pages-popuphome-js": () => import("./../../../src/pages/popuphome.js" /* webpackChunkName: "component---src-pages-popuphome-js" */),
  "component---src-pages-project-component-js": () => import("./../../../src/pages/project-component.js" /* webpackChunkName: "component---src-pages-project-component-js" */),
  "component---src-pages-project-unit-js": () => import("./../../../src/pages/project_unit.js" /* webpackChunkName: "component---src-pages-project-unit-js" */),
  "component---src-pages-react-home-react-js": () => import("./../../../src/pages/react/home-react.js" /* webpackChunkName: "component---src-pages-react-home-react-js" */),
  "component---src-pages-react-react-notification-detail-js": () => import("./../../../src/pages/react/react_notification_detail.js" /* webpackChunkName: "component---src-pages-react-react-notification-detail-js" */),
  "component---src-pages-react-react-notification-js": () => import("./../../../src/pages/react/react_notification.js" /* webpackChunkName: "component---src-pages-react-react-notification-js" */),
  "component---src-pages-react-tender-notification-detail-js": () => import("./../../../src/pages/react/Tender_notification_detail.js" /* webpackChunkName: "component---src-pages-react-tender-notification-detail-js" */),
  "component---src-pages-react-tender-notification-js": () => import("./../../../src/pages/react/Tender_notification.js" /* webpackChunkName: "component---src-pages-react-tender-notification-js" */),
  "component---src-pages-report-studies-js": () => import("./../../../src/pages/report_studies.js" /* webpackChunkName: "component---src-pages-report-studies-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-rti-js": () => import("./../../../src/pages/RTI.js" /* webpackChunkName: "component---src-pages-rti-js" */),
  "component---src-pages-screenreader-js": () => import("./../../../src/pages/screenreader.js" /* webpackChunkName: "component---src-pages-screenreader-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-show-tag-js": () => import("./../../../src/pages/show_tag.js" /* webpackChunkName: "component---src-pages-show-tag-js" */),
  "component---src-pages-slider-2-js": () => import("./../../../src/pages/slider2.js" /* webpackChunkName: "component---src-pages-slider-2-js" */),
  "component---src-pages-social-media-facebook-js": () => import("./../../../src/pages/social_media/facebook.js" /* webpackChunkName: "component---src-pages-social-media-facebook-js" */),
  "component---src-pages-social-media-instagram-js": () => import("./../../../src/pages/social_media/instagram.js" /* webpackChunkName: "component---src-pages-social-media-instagram-js" */),
  "component---src-pages-social-media-social-tab-js": () => import("./../../../src/pages/social_media/social_tab.js" /* webpackChunkName: "component---src-pages-social-media-social-tab-js" */),
  "component---src-pages-social-media-twiiter-x-js": () => import("./../../../src/pages/social_media/twiiter_x.js" /* webpackChunkName: "component---src-pages-social-media-twiiter-x-js" */),
  "component---src-pages-social-media-youtube-js": () => import("./../../../src/pages/social_media/youtube.js" /* webpackChunkName: "component---src-pages-social-media-youtube-js" */),
  "component---src-pages-social-media-youtubeplayer-js": () => import("./../../../src/pages/social_media/youtubeplayer.js" /* webpackChunkName: "component---src-pages-social-media-youtubeplayer-js" */),
  "component---src-pages-sustainable-js": () => import("./../../../src/pages/Sustainable.js" /* webpackChunkName: "component---src-pages-sustainable-js" */),
  "component---src-pages-tag-list-js": () => import("./../../../src/pages/TagList.js" /* webpackChunkName: "component---src-pages-tag-list-js" */),
  "component---src-pages-tender-display-js": () => import("./../../../src/pages/tender_display.js" /* webpackChunkName: "component---src-pages-tender-display-js" */),
  "component---src-pages-tender-js": () => import("./../../../src/pages/tender.js" /* webpackChunkName: "component---src-pages-tender-js" */),
  "component---src-pages-term-js": () => import("./../../../src/pages/term.js" /* webpackChunkName: "component---src-pages-term-js" */),
  "component---src-pages-testgallery-js": () => import("./../../../src/pages/testgallery.js" /* webpackChunkName: "component---src-pages-testgallery-js" */),
  "component---src-pages-thematic-studies-js": () => import("./../../../src/pages/thematic_studies.js" /* webpackChunkName: "component---src-pages-thematic-studies-js" */),
  "component---src-pages-trigger-run-js": () => import("./../../../src/pages/trigger_run.js" /* webpackChunkName: "component---src-pages-trigger-run-js" */),
  "component---src-pages-update-of-week-js": () => import("./../../../src/pages/update_of_week.js" /* webpackChunkName: "component---src-pages-update-of-week-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-village-chart-js": () => import("./../../../src/pages/village-chart.js" /* webpackChunkName: "component---src-pages-village-chart-js" */),
  "component---src-pages-village-info-2-js": () => import("./../../../src/pages/village-info2.js" /* webpackChunkName: "component---src-pages-village-info-2-js" */),
  "component---src-pages-village-info-js": () => import("./../../../src/pages/village-info.js" /* webpackChunkName: "component---src-pages-village-info-js" */),
  "component---src-pages-villagehomechart-js": () => import("./../../../src/pages/villagehomechart.js" /* webpackChunkName: "component---src-pages-villagehomechart-js" */),
  "component---src-pages-week-update-js": () => import("./../../../src/pages/week_update.js" /* webpackChunkName: "component---src-pages-week-update-js" */),
  "component---src-pages-who-is-who-js": () => import("./../../../src/pages/who_is_who.js" /* webpackChunkName: "component---src-pages-who-is-who-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-display-project-component-js": () => import("./../../../src/templates/display_project_component.js" /* webpackChunkName: "component---src-templates-display-project-component-js" */),
  "component---src-templates-newlater-js": () => import("./../../../src/templates/newlater.js" /* webpackChunkName: "component---src-templates-newlater-js" */),
  "component---src-templates-notice-js": () => import("./../../../src/templates/notice.js" /* webpackChunkName: "component---src-templates-notice-js" */),
  "component---src-templates-tender-js": () => import("./../../../src/templates/tender.js" /* webpackChunkName: "component---src-templates-tender-js" */),
  "component---src-templates-update-of-weeks-js": () => import("./../../../src/templates/update_of_weeks.js" /* webpackChunkName: "component---src-templates-update-of-weeks-js" */)
}

